module.exports = {
  'en-us': {
    default: true,
    path: '/en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: 'AI-driven Business Planning - Quantics',
    defaultDescription:
      'Quantics offers an AI-driven business planning solution to deliver highly precise forecasts for better decision making and better results.',
    menu: {
      solutions: 'Solutions',
      services: 'Services',
      industries: 'Project',
      resources: 'Blog',
      about_us: 'About us',
    },
    buttons: {
      meeting: 'Contact us',
      video: 'Watch our video',
      discuss: 'Let’s discover new possibilities',
      project: 'Let’s talk about the project',
      learn_more: 'Learn more',
      download_ebook: 'Download Ebook',
      subscribe: 'Subscribe',
    },
    footer: {
      about_us: 'About Us',
      imprint: 'Imprint',
      services: 'Professional Services',
      partner: 'Partnership',
      predict: 'Predict Sales',
      optimize: 'Optimize Inventory',
      plan: 'Plan Finance',
      company: 'Company',
    },
    dropdown: {
      solution: {
        value_proposition: 'Increase your EBT by up to 14%.',
        predict: {
          headline: 'Predict Sales',
          desp: 'Benefit from automated and accurate forecasts.',
        },
        integrate: {
          headline: 'Integrate with Lightspeed',
          desp: 'Enjoy a fast and cost-efficient implementation.',
        },
        optimize: {
          headline: 'Optimize Replenishment',
          desp: 'Rely on effective and dynamic replenishment.',
        },
        plan: {
          headline: 'Plan Finance',
          desp: 'Simplify your financial planning with hierarchical forecasts.',
        },
      },
      industries: {},
    },
    consent: {
      headline: 'Let’s talk about cookies.',
      p1:
        'Our website uses cookies mainly for analytical reasons. Cookies help us to to provide you a better experience on our website and tailor our services towards your needs.',
      p2: 'You can learn more about how we use cookies in our',
      link: 'Privacy Policy.',
      accept: 'Accept',
      decline: 'Decline',
    },
    form: {
      title: 'Only one more step.',
      meeting: {
        first_name: 'First name',
        last_name: 'Last name',
        work_email: 'Business E-Mail',
        company_name: 'Company',
        job_title: 'Position',
        message: 'Enter message or question here ...',
        button: 'Request now',
      },
      error: {
        first_name: 'First name is required',
        last_name: 'Last name is required',
        work_email: 'Business E-Mail is required',
        valid_email: 'Invalid E-Mail address',
      },
      message: {
        headline: 'Your request has been sent.',
        desp: 'We will get back in touch with you as soon as possible!',
      },
      notice:
        'By clicking the button, you consent to our sales team using the information you provide to inform you about our products and services.',
    },
    subscribe: {
      headline: 'Subscribe now.',
      desp: "Don't miss any news and get helpful insights.",
      message: {
        headline: 'Thank you!',
        desp:
          'Make sure to add us to your email whitelist to get all insights and tips.',
      },
      email: 'Enter your email now ...',
      button: 'Subscribe',
    },
    resources: {
      most_popular: {
        headline: 'Most popular',
        button: 'Read now',
      },
      show_more: 'Show more',
      search: {
        title: 'What are you looking for?',
        enter: 'Enter your search terms here',
      },
      category: {
        all: 'All',
        blog: 'Blog post',
        infographics: 'Infographics',
      },
    },
    about_us: {
      latest_news: {
        button: 'Read news',
      },
    },
    services: {
      title: 'Our services',
    },
  },
}
