import React, { useState, useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Social from '../components/social'
import { GlobalStyles } from 'twin.macro'
import { GlobalStyle } from '../utils/global-styles'
import i18n from '../../config/i18n'
import Helmet from 'react-helmet'
import tw from 'twin.macro'
import LocalizedLink from './localized-link'
const LocaleContext = React.createContext()

const Layout = ({
  children,
  pageContext: { locale, resourceList },
  location,
}) => {
  useEffect(() => {
    window.pipedriveLeadboosterConfig = {
      base: 'leadbooster-chat.pipedrive.com',
      companyId: 123,
      playbookUuid:
        locale === 'en-us'
          ? 'xxx'
          : 'xxx',
      version: 2,
    }
    ;(function () {
      var w = window
      if (w.LeadBooster) {
        console.warn('LeadBooster already exists')
      } else {
        w.LeadBooster = {
          q: [],
          on: function (n, h) {
            this.q.push({ t: 'o', n: n, h: h })
          },
          trigger: function (n) {
            this.q.push({ t: 't', n: n })
          },
        }
      }
    })()
  }, [locale])
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <GlobalStyles />
      <GlobalStyle />
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-2DSK6XDBL1"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-2DSK6XDBL1');`}
      </script>
      </Helmet>
      <Header location={location} locale={locale} resourceList={resourceList} />
      {/*
      <Social />
          */}
      {children}
      <Footer />
    </LocaleContext.Provider>
  )
}
export { LocaleContext, Layout }
