import React from 'react'
import logo from '../images/logo.svg'
import { LogoWrapper } from '../utils/styles'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import background from '../images/footer-background.svg'
import Twitter from '../images/Twitter.svg'
import YouTube from '../images/YouTube.svg'
import LinkedIn from '../images/LinkedIn.svg'
import Mail from '../images/Mail.svg'
import Phone from '../images/Phone.svg'
import Location from '../images/Location.svg'
import LocalizedLink from './localized-link'
import { LocaleContext } from './layout'

const FooterWrapper = styled.div`
  background: #F3F5F5;
  background-repeat: no-repeat;
  background-size: cover;
`
const FooterLinksWrapper = styled.div({
  ...tw`border-t border-ocean grid grid-cols-1 md:grid-cols-5 gap-4 my-6`,
  '--tw-border-opacity': '0.15',
  '> div': {
    '> .links-name': {
      ...tw`font-black text-lg pb-4 text-navy`,
    },
    '> a': tw`block text-ocean py-3`,
  },
  '@media (max-width: 768px)': {
    ...tw`my-5 py-8`,
  },
})
const LogoTextWrapper = styled.div({
  ...tw`font-bold py-4 text-navy`,
  '> span': {
    ...tw`md:border-l md:ml-4 md:pl-4 border-ocean text-sm`,
    '--tw-border-opacity': '0.15',
    'letter-spacing': '1.5px',
  },
})
const SocialLink = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  a {
    img {
      width: 24px;
      height: 24px;
    }
  }
`
const ContactWrapper = styled.div({
  ...tw`flex py-2`,
  '> img': {
    width: '24px',
    height: '24px',
    'margin-right': '15px',
  },
})

const Footer = (props) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <FooterWrapper>
      <div tw="container py-10">
        <div tw="md:flex justify-between">
          <div tw="md:flex">
            <LocalizedLink to="/">
              <LogoWrapper>
                <img src={logo} />
              </LogoWrapper>
            </LocalizedLink>
            <LogoTextWrapper>
              <span>Build, Grow, and Maintain 
your Online Store</span>
            </LogoTextWrapper>
            </div>
          {/*
          <div tw="grid grid-cols-3 gap-1 md:gap-8">
            <SocialLink>
              <Link to="https://www.linkedin.com/company/quantics-io/">
                <img src={LinkedIn} />
              </Link>
            </SocialLink>
            <SocialLink>
              <Link to="https://www.youtube.com/channel/UC9Qt0olS6u7IEiq8j8aSoeQ">
                <img src={YouTube} />
              </Link>
            </SocialLink>
            <SocialLink>
              <Link to="https://twitter.com/Quantics_io">
                <img src={Twitter} />
              </Link>
            </SocialLink>
          </div>
           */}
        </div>
       
        <FooterLinksWrapper>
          
        </FooterLinksWrapper>
       
        <div tw="md:flex justify-between">
          <span tw="text-sm text-ocean">
            Copyright © 2022 Bridment. All Rights Reserved.
          </span>
         {/*
          <LocalizedLink to="/privacy-policy">
            <span tw="text-sm text-ocean">Privacy Policy</span>
          </LocalizedLink>
          */}
        </div>
      </div>
    </FooterWrapper>
  )
}
export default Footer
